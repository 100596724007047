<template>
      
  <div class="all">
    <header>
      <img :src="banner" alt="" />
    </header>
    <!-- <aside class="action">
      首页 &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;资讯中心 &nbsp;<img
        src="../assets/img/InforCenter/juxing14.png"
        alt=""
      />
      &nbsp;通知公告
    </aside> -->
    <mainBox></mainBox>
    
    <aside class="action2">
      <div class="dev">
        <div class="inputs">
          <input type="text" placeholder="搜索您感兴趣的新闻" v-model="value" />
          <img src="../assets/tourService/ic_search2[1].png" @click="sreach">
        </div>
        <div class="btn" @click="sreach">搜索</div>
      </div>
    </aside>
    <aside class="body">
      <div class="list" v-for="(item, key) in obj" :key="key">
        <div class="li"  v-if="item.news_img != ''">
          <div>
            <img :src="item.news_img" alt=""  @click="skip(item.id)"/>

            <div class="liRight">
              <span class="title" @mousedown.stop="an" @mouseup.stop="song(item.id)">{{ item.news_title }}</span>
              <p class="miao">
                {{ item.news_scontent }}
                <!-- 2021年元旦假期将至，为满足观众朋友们的参观需求，我馆将在2021年1月1日至1月3日正常对外开放。届时，请配合我馆工作人员的现场指示，文明入馆参观。 -->
              </p>
              <span class="time"> 时间:{{item.news_time | time}} </span>

              <button class="GoDetail" @click="skip(item.id)">
                查看详情》
              </button>
            </div>
          </div>
        </div>
        <div class="lisd" v-if="item.news_img.length == 0 && !item.news_img">
          <div>
            <div class="liLeft">
              <span class="title"  @mousedown.stop="an" @mouseup.stop="song(item.id)">{{ item.news_title }}</span>
              <p class="leftmiao">
                {{ item.news_scontent }}
              </p>
              <span class="time"> 时间:{{item.news_time | time}}</span>

              <button class="GoDetail" @click="skip(item.id)">查看详情》</button>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
      <Pagetion :total="pages2" :pageSize="8" @ClickChange="handleCurrentChange"></Pagetion>
      
      </div>
    </aside>
    

    <Footer></Footer>
  </div>
</template>

<script>
import mainBox from "@/components/main.vue";
import Footer from "../components/footer";
import { fetch } from "../api/https";
import Pagetion from '../components/pagetion/pagetion.vue';
import moment from 'moment'
export default {
  data() {
    return {
      pageSize:5,
      value:'',
      obj: [
      ],
      pages2: 1,
      currentPage: 5,
      // news_title:'',
      page:1,
      banner:'',
            timeLength:0,
      oldtime:0,
    };
  },
  components:{
      Footer,
    Pagetion,
    mainBox
  },
    filters:{
    time(num){
      return moment(1000*parseInt(num)).format("YYYY-MM-DD")
    }
  },
  created() {},
  mounted() {
    this.require()
  },
  methods: {
        an(){ //鼠标按下
       this.timeLength = new Date().getTime()
    },
    song(id){ //鼠标松开 判断鼠标点击的时间
       this.oldtime = new Date().getTime()
       if((this.oldtime-this.timeLength) < 130){
                 this.$router.push(`/deta?id=${id}`)
       }
       
    },
    handleSizeChange(val) {
      },
      handleCurrentChange(val) {
        
      },
    sreach(){
      // this.news_title = this.value
      this.require()
    },
    require() {
      fetch('/getmenu').then((res) => {
         var  id = res.data[6].child[2].id
          this.banner = res.data[6].child[2].menu_img
      document.title = res.data[6].child[2].menu_seo_title
      fetch("/getnewslist",{menuid:id, news_title:this.value,page:this.page,pagesize:8})
        .then((res) => {

             let sub = {
              page:this.pages2,
          }
          this.obj = res.data.list;
          this.pages2 = res.data.total_count;
          
        })
        .catch((err) => {});
        })
    },
    handleCurrentChange(val) {
      this.page = val;
      this.require()
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    skip(value) {
    this.$router.push(`/deta?id=${value}`)
    sessionStorage.setItem('erji','通知公告')
    },
  },
};
</script>

<style  scoped>

.all{
  width: 100%;
  height: auto;
  background-color: rgb(249, 249, 249);
}
header {
  width: 100%;
}
header img {
  width: 100%;
  height: 300px;
  margin: 0 auto;
}
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  color: #1a1a1a;
font-size: 16px;
font-family: PingFangSC;
font-weight: 400;
color: #1A1A1A;
line-height: 60px;
}
.action2 {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  color: #1a1a1a;
  margin-top: 15px;
  height: 60px;
}
.action2 .dev {
  width: 500px;
  float: right;
}
.inputs {
  background: url("../assets/img/InforCenter/juxing3.png");
  background-size: 340px 43px;
  width: 341px;
  height: 43px;
  margin-top: 10px;
  float: left;
  position: relative;
}
.inputs img{
  position: absolute;
  right: 21px;
  top: 13px;
  width: 16px;
  height: 16px;
}
.inputs input {
  position: absolute;
  left: 19px;
  top: 14px;
  border: none;
  width: 80% !important;
  
  background: none;
  outline: none;
  font-size: 13px;
}
.btn {
  background: url("../assets/img/InforCenter/juxing7.png");
  background-size: 126px 43px;
  width: 126px;
  height: 43px;
  float: right;
  text-align: center;
  line-height: 40px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
}
.body {
  max-width: 1400px;
  padding: 50px 100px;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  background: #ffffff;
  margin-top:30px;
  margin-bottom: 50px;
}
.list {
  width: 100%;
  min-height: 248px;
}
.li {
  position: relative;
  height: 180px;
  padding: 34px;
 }
.li img {
  height: 180px;
  width: 272px;
  float: left;
  margin-right: 59px;
}
.GoDetail:hover{
  background-color: rgb(198, 134, 59);
  color: white;
}
.liRight {
  width: 800px;
  float: right;
  text-align: left;
}
.title {
width: 329px;
height: 19px;
font-size: 19px;
font-family: PingFangSC;
font-weight: 530;
color: #333333;
line-height: 28px;
cursor: pointer;
}
.title:hover{
  color: #c19c66 !important;
}
.miao {
  margin-top: 30px;
  color: #999999;
  font-size: 16px;
font-family: PingFangSC;
font-weight: 400;
  line-height: 2;
  display: -webkit-box;
overflow: hidden;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
width: 82%;
-webkit-line-clamp: 2;
height: 64px;
}
.leftmiao{
    margin-top: 30px;
  color: #999999;
  font-size: 16px;
font-family: PingFangSC;
font-weight: 400;
  line-height: 2;
  display: -webkit-box;
overflow: hidden;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
height: 60px;

-webkit-line-clamp: 2;
}
.time {
  color: #999999;
  font-size: 16px;
  margin-top: 33px;
  display: block;
}
.GoDetail {
  width: 116px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  outline: none;
  font-size: 16px;
  /* position: absolute;
  bottom: 44px;
  right: 44px; */
  cursor: pointer;
  color:#333333;
  float: right;
  margin-top: -44px;
  
}
.liLeft {
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: left;
  }
.lisd {
  position: relative;
  min-height: 180px;
  padding: 34px;
  margin-top:20px;
    border:1px solid #cccccc;
  padding: 33px;
  }
.lisd:hover{
  border:1px solid #D89432;
  padding: 33px;
  }
.page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 60px 0;
}
el-pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}
>>>.btn-prev {
  width: 60px;
  height: 40px;
}
>>> .number {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
.el-pager >>> li {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>> .btn-next {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}

>>> .more {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
 .el-pager .more::before {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>>.el-pagination__total{
  margin-left: 5px;font-weight: 600;  
  transform: scale(1.19);

}
@media screen  and (max-width: 1439px) {
  .title {
  line-height: 23px;
  }
  .action2{
    width: 1266px;
  }
  .body{
    width: 1266px;
  }
  .li {
  position: relative;
  height: 180px;
  padding: 34px;
 }
  .liRight {
      width: 650px;
      float: right;
      padding-top: 15px;
      padding-bottom: 10px;
      text-align: left;
  }
  .time{
    margin-top: 25px;
  }
  .page{
      width: 1266px;
      margin: 0 auto;
      margin-left: -100px;
  }
  .miao{
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 82%;
  }
}
</style>
<style>
el-pagination {
  position: absolute;
  right: 30%;
  top: 20px;
}
.btn-prev {
  min-width: 74px;
  height: 40px;
}
>>> .number {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
.el-pager >>> li {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>> .btn-next {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}

>>> .more {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
 .el-pager .more::before {
  width: 60px;
  line-height: 40px;
  text-align: center;
  height: 40px;
}
>>>.el-pagination__total{
  margin-top: 6px;
  margin-left: 5px;font-weight: 600;  transform: scale(1.19);

}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #C19C66;
  color: #FFF;
}
.el-pagination.is-background .el-pager li.active {
  color: #fff;
  cursor: default;
}
.el-pagination.is-background .el-pager li:hover {
  color:#C19C66;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #C19C66;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #C19C66;
  color: #FFF;
}
</style>